// config.js
const apiconfig = {
    REACT_APP_MAIN_BASE_CONFIG_URL: process.env.REACT_APP_MAIN_BASE,
    REACT_APP_BASE_API_CONFIG_URL: process.env.REACT_APP_BASE_API,
};
  window.REACT_APP_BASE_API_CONFIG_URL = "ttt"
  console.log(window.location.hostname, process.env.REACT_APP_MAIN_BASE, process.env.REACT_APP_DOMAIN_TEST)
  if( [process.env.REACT_APP_DOMAIN_TEST, "127.0.0.1"].includes(window.location.hostname) ){
    apiconfig.REACT_APP_MAIN_BASE_CONFIG_URL= process.env.REACT_APP_MAIN_BASE_TEST || "";
    apiconfig.REACT_APP_BASE_API_CONFIG_URL= process.env.REACT_APP_BASE_API_TEST || "";

    window.REACT_APP_MAIN_BASE= process.env.REACT_APP_MAIN_BASE_TEST || "";
    window.REACT_APP_BASE_API= process.env.REACT_APP_BASE_API_TEST || "";
  }
  else{
    window.REACT_APP_MAIN_BASE= process.env.REACT_APP_MAIN_BASE || "";
    window.REACT_APP_BASE_API= process.env.REACT_APP_BASE_API || "";

  }
  
  export default apiconfig;
  